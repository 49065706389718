import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { List, Box, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Divider, IconButton, Dialog, DialogActions, DialogContent } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SettingsIcon from '@mui/icons-material/Settings';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import GavelIcon from '@mui/icons-material/Gavel';
import ReactMarkdown from 'react-markdown';

import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';
import NavBar from './NavigationBar';
import productPlan from '../PRODUCTPLAN_DE.md';
import config from '../config';

const drawerWidth = config.ui_settings.drawer_width; // 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
  ({ theme, drawerOpen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(drawerOpen && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!drawerOpen && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Navigation() {
  const { drawerOpen, setDrawerOpen } = useAppContext();
  const [logo, setLogo] = React.useState('nukleus');
  const [markdown, setMarkdown] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeLabel, setActiveLabel] = useState('');
  const { currentTenant } = useAuth();

  const subMenuItems = ['Produktplan', 'Feedback melden', 'Einstellungen'];
  const links = ['', 'https://forms.office.com/e/ZruJY1sAkB', ''];
  const navMMenuItems = [
    { label: 'Suche', icon: <SearchIcon />, route: '/', type: 'link' },
    { label: 'Projekte', icon: <FolderCopyIcon />, route: '/projects', type: 'link' },
    {
      label: 'Berichte', icon: <AssessmentIcon />, type: 'header', children: [
        { label: 'Akzeptanzkriterien', icon: <GavelIcon />, route: '/reports/criteria', type: 'link' },
        { label: 'Berichtdefinitionen', icon: <RuleFolderIcon />, route: '/reports/templates', type: 'link' },
        { label: 'Berichtergebnisse', icon: <FactCheckIcon />, route: '/reports/instances', type: 'link' },
      ]
    },
  ];

  const isModuleAvailable = (moduleName) => {
    const { modules } = config.envs[config.active_env];
    const excluded_modules = config.client_modules_exclude[currentTenant];
    const module_included_for_env = modules.includes(moduleName);
    if (excluded_modules && excluded_modules != undefined && excluded_modules.includes(moduleName)) { 
      return false;
    } else {
      return module_included_for_env;
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuItemClick = (item) => {
    setActiveLabel(item.label);
    navigate(item.route);
  };

  useEffect(() => {
    // Find the menu item based on the route
    const setActiveFromPath = () => {
      const path = location.pathname;
      const activeItem = navMMenuItems.find(item => item.route === path) || 
      navMMenuItems.flatMap(item => item.children || []).find(subItem => subItem.route === path);

      if (activeItem) {
        setActiveLabel(activeItem.label);
      }
    };

    setActiveFromPath();

    fetch(productPlan).then((response) => response.text()).then((text) => {
      setMarkdown(text);
    });
  }, [location]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar position="fixed" open={drawerOpen} handleDrawerOpen={handleDrawerOpen} title={activeLabel} />
      <Drawer variant="permanent" drawerOpen={drawerOpen} sx={{ display: 'flex' }}>
        <DrawerHeader>
          <img src={`/${logo}.png`} height={35} sx={{ marginRight: '50px !important' }} alt="logo" />
          <IconButton onClick={handleDrawerClose} sx={{ marginLeft: '30px' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        {navMMenuItems.map((item, index) => (
            isModuleAvailable(item.label) && (
              <React.Fragment key={item.label}>
                {item.type === 'link' ? (
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      key={item.label}
                      selected={item.label === activeLabel}
                      onClick={() => handleMenuItemClick(item)}
                      sx={{
                        minHeight: 48,
                        justifyContent: drawerOpen ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 3 : 'auto', justifyContent: 'center' }}>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <React.Fragment key={item.label}>
                    <Divider />
                    {drawerOpen && (
                      <Typography variant="overline" display="block">
                        {item.label}
                      </Typography> 
                    )}
                  </React.Fragment>
                )}
                {item.children && (
                  <React.Fragment>
                    <List component="div" disablePadding>
                      {item.children.map((subItem) => (
                        isModuleAvailable(subItem.label) && (
                          <ListItem key={subItem.label} disablePadding>
                            <ListItemButton
                              key={subItem.label}
                              selected={subItem.label === activeLabel}
                              onClick={() => handleMenuItemClick(subItem)}
                              sx={{
                                minHeight: 48,
                                justifyContent: drawerOpen ? 'initial' : 'center',
                                px: 2.5,
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 3 : 'auto', justifyContent: 'center' }}>{subItem.icon}</ListItemIcon>
                              <ListItemText primary={subItem.label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                            </ListItemButton>
                          </ListItem>
                        )
                      ))}
                    </List>
                    <Divider />
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          ))}
        </List>
        {/* <Divider /> */}
        <List>
          {subMenuItems.map((text, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                component={(links[index].length > 0) ? "a" : ''}
                target='_blank'
                disabled={index > 1}
                href={links[index]}
                key={text}
                onClick={index === 0 ? handleOpen : null}
                sx={{
                  minHeight: 48,
                  justifyContent: drawerOpen ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index === 0 ? <FlightTakeoffIcon /> :
                    index === 1 ? <FeedbackIcon /> : <SettingsIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: drawerOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Typography variant='button' sx={{ fontSize: '0.70rem', bottom: 0, display: drawerOpen ? 'initial' : 'none' }}>Version 0.1.13 (Alpha)</Typography>
      </Drawer> 

      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <ReactMarkdown children={markdown} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
