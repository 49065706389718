import config from "../config";

const openFile = '&openfile=';
const nextcloudCombinedPath = config.envs[config.active_env].domain + config.nextcloud_path;

export const getUrlForPath = (originalPath) => {
    if (originalPath !== '' && originalPath.length > 1) {
        const modifiedPath = originalPath.substring(1); 
        return nextcloudCombinedPath + modifiedPath;
    } else {
        return "";
    }
};

export const getUrlForProjectPath = (project_path, doc_id) => {
    if (project_path !== '' && project_path.length > 1 && /\//.test(project_path)) {
        let modified_path = project_path.substring(1)
        let last_slash_pos = modified_path.lastIndexOf('/')
        if (last_slash_pos !== -1){
          modified_path = modified_path.substring(0, last_slash_pos);
        }
        return nextcloudCombinedPath + modified_path + openFile + doc_id;
    } else {
        return "";
    }
};

export const getUrlForDocument = (path, document_id) => {
    if (path && path != '' && document_id) {
        let filepaths_no_file = path.split('/').filter(entry => entry.trim() != '').slice(0,-1);
        return nextcloudCombinedPath + filepaths_no_file.join('/') + openFile + document_id;
    } else {
        return "";
    }
};