const config = {
    mui_license: '3a702a384fd8d364b1c0047b8ac04953Tz03MzUyNyxFPTE3MjQ2ODUzMjQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
    oai: 'c2stcHJvai0=',
    k_exp: 'TXFxRjNsVTl5UEtJWTdhejlHbnVUM0JsYmtGSkxLRUZXSXh4SFRTWjlpMTRRRjR0',
    requests_content_type: 'application/json',
    mock_server_api_url: "https://mock.nukleus.technology/",
    use_mock_data: false,
    mock_credentials: {user:'nukleus', password:'nukleus'},
    active_env: "prod",
    envs: {
        "dev":{ 
            domain: "https://dev.nukleus.technology/",
            client_secret: '0FBHhxSg6wn61yeFY9VlkTN1YvoRHfXX/jVOiWS0',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: ['Suche', 'Projekte', 'Berichte', 'Akzeptanzkriterien', 'Berichtdefinitionen', 'Berichtergebnisse']
        },
        "stg":{ 
            domain: "https://stg.nukleus.technology/",
            client_secret: 'ffoiYFzRpsf9Oisxg7SPz6WBjamy8x8JR7xNzsNy',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: ['Suche', 'Projekte', 'Berichte', 'Akzeptanzkriterien', 'Berichtdefinitionen', 'Berichtergebnisse']
        },
        "prod":{
            domain: "https://nukleus.technology/",
            client_secret: 'r/u3aV4bexEujluMZA4LA8HKfDR+0LGXr9iiC3Sj',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: ['Suche', 'Projekte', 'Berichte', 'Akzeptanzkriterien', 'Berichtdefinitionen', 'Berichtergebnisse']
        }
    },
    nextcloud_path: "nextcloud/apps/files/?dir=/",
    api_path: "api/v1/",
    task_service: "tasks/",
    client_id: 'frontend',
    client_modules_exclude: {
        "armasuisse": ['Berichte', 'Akzeptanzkriterien', 'Berichtdefinitionen', 'Berichtergebnisse'],
        "debbuger": [ 'Berichtergebnisse']
    },
    assistant: {
        base_system_prompt: "You are a helpful assistant skilled at generating structured documents by validating information sources and formatting the data based on user input and context. You output only the results of your work. All generated outputs are in German in swiss writing style (without Scharfes 's' (ß)).",
        system_role:"system",
        user_role:"user",
        modules:{
            "debbuger":['Datenextraktion'],
            "debugging":['Datenextraktion'],
            "test1":[], 
            "my hypotheca":['Datenextraktion']
        },
        steps: {
            0:'Konfiguration',
            1:'Ergebnisse'
        },
        phase_mapping:{
            "Phase 11": 1,
            "Phase 21": 2,
            "Phase 22": 3,
            "Phase 31": 4,
            "Phase 32": 5,
            "Phase 33": 6,
            "Phase 41": 7,
            "Phase 51": 8,
            "Phase 52": 9,
            "Phase 53": 10
        },
        testing_phase: "vorpruefung",
        uses: [
            "wohnen", "verwaltung", "bildungsbauten", "erdgeschossnutzung"
        ],
        processes: [
            {
                name: "Pflichtenheft",
                type: "Default",
                category: "Vertragrelevant",
                prompt: "The output should be in German swiss style writing. Ich möchte ein Pflichtenheft erstellen. BItte berücksichtige andere bereits vorhandenen Pflichtenhefte und grenze das aktuelle von denen ab. Bitte erstelle auf Grundlage von relevanten Projektinformationen, Feststellung, Bewertungen ein Pflichtenheft und benenne die Firmen mit Namen und Adresse, Einleitung und Zielsetzung, Anforderungen, Rahmenbedingungen, Spezifikationen, Schnittstellen, Qualitätsanforderungen, Verifizierung und Validierung, Änderungsmanagement, Glossar.",
                contexts: ["projektdetails", "vorgaben", "strategie", "berichte"], // Pre-selected appropriate contexts
                description: "Erstellen Sie ein Anforderungsdokument für das Projekt.",
                questions: ["Was sind die Anforderungen?", "Welche Abteilungen sind beteiligt?"],
                output: 'Dokument'
            },
            {
                name: "Projektdokumentation",
                type: "Default",
                category: "Vertragrelevant",
                prompt: "The output should be in German swiss style writing. Output only the results. You will be provided with a list of required documents according to KBOB in the context named 'kbob'. Format the list so that it is grouped by Fachbereich, and keep all details provided. If the context 'kbob' is empty, respond with 'keine Dokumente entsprechen den angegebenen Filtern.'",
                contexts: ["projektdetails", "kbob"], 
                description: "Erstellen Sie eine Übersicht nach KBOB über die Abschlussdokumentation des Projekts.",
                questions: [],
                output: 'Dokument'
            },
            {
                name: "Mängelrüge",
                type: "Default",
                category: "Vertragrelevant",
                prompt: "The output should be in German swiss style writing. Wir haben einen Vertrag mit einem Unternehmer. Welche Rechtsgrundlage und Paragrafen nach schweizer Recht sind anwendbar? Bitte erstelle auf Grundlage dieser Feststellung, relevanten Protokollen der Vertragsgrundlage eine Mängelrüge als Anschreiben und benenne beide Firmennamen, Adressen, Ansprechpartner und dne Betreff des Mangels. Wenn ein klarer Mangel und der Bewergrund im Protokoll festgestellt und beschrieben worden ist und dieser zur aktuellen PRoblemstellung passt bitte diesen explizit aufnehmen.",
                contexts: ["projektdetails", "strategie", "protokolle"],
                description: "Melden Sie Mängel im Projekt.",
                questions: ["Welche Mängel wurden gefunden?", "Wer ist verantwortlich?"],
                output: 'Dokument'
            },
            {
                name: "Nachtragspotenzial Analyse",
                type: "Default",
                category: "Berichtrelevant",
                prompt: "The output should be in German swiss style writing. Erstelle eine Bewertung für das Nachtragspotenzial und Identifiziere wesentliche Risiken auf Basis von: den individuellen Zielen vom Fachbereich, der aktuellen Aufgaben, Änderung der Projektanforderungen, Risiken und unvorhergesehene Ereignisse, Kommunikationsprobleme, Vertragslücken, Technische Herausforderungen, Datenschutzverletzungen erstelle ein eEinschätzung zu den thematische Beiträge die zu Nachtragspotenzialen führen können.",
                contexts: ["projektdetails", "strategie", "protokolle", "aufgaben", "vorgaben"],
                description: "Analysieren Sie potenzielle Nachtragsforderungen.",
                questions: ["Welche Nachträge sind offen?", "Welche Fristen gibt es?"],
                output: 'Dokument'
            },
            {
                name: "Risikobericht",
                type: "Default",
                category: "Berichtrelevant",
                prompt: "The output should be in German swiss style writing. Erstelle einen Risikobericht unter Berücksichtigung folgender Aspakte: Änderung der Projektanforderungen, Risiken und unvorhergesehene Ereignisse, Kommunikationsprobleme, Vertragslücken, Technische Herausforderungen, Datenschutzverletzungen.",
                contexts: ["projektdetails", "strategie", "berichte", "vorgaben"],
                description: "Erstellen Sie einen Risikobericht für das Projekt.",
                questions: ["Welche Risiken bestehen?", "Welche Maßnahmen wurden getroffen?"],
                output: 'Dokument'
            },
            {
                name: "Koordinationsbericht",
                type: "Default",
                category: "Berichtrelevant",
                prompt: "The output should be in German swiss style writing. Erstelle einen Koordinationsbericht unter Berücksichtigung der Bericht und wesentlichen identifizieren Aufgaben. Die Prorisiung erfolgt unter Berücksichtungung entsprechender Fachbereiche, zugewiesen und Fälligkeitsdaten. Bitte die Aufgaben chronologisch auflisten.",
                contexts: ["projektdetails", "vorgaben", "aufgaben", "berichte"],
                description: "Erstellen Sie einen Koordinationsbericht für verschiedene Teams.",
                questions: ["Welche Teams sind beteiligt?", "Was ist der Fortschritt der Teams?"],
                output: 'Tabelle'
            },
            {
                name: "Aufgabenanalyse und Priorisierung",
                type: "Default",
                category: "Berichtrelevant",
                prompt: "The output should be in German swiss style writing. Berücksichtige die Aufgaben der letzten Woche. Bitte Auflistung der Aufgaben gruppiert nach Fachbereichen, priorisiert nach Fälligkeitsdatum. Zusammen mit der Information über den Ablageort. Bedingungen: wenn kein Fachbereich benannt einen Fachbereich vorschlagen, wenn kein Fälligkeitsdatum vorhanden, ein Fälligkeitsdatum im Kontext der Aufgaben vorschlagen. Alle Vorschläge mittels dem Verweis 'nukleus Vorschlag' identifizieren.",
                contexts: ["projektdetails", "aufgaben"],
                description: "Analysieren und priorisieren Sie Aufgaben im Projekt.",
                questions: ["Welche Aufgaben sind offen?", "Welche Prioritäten wurden gesetzt?"],
                output: 'Tabelle'
            },
            {
                name: "Vorprüfung SNBS",
                type: "Default",
                category: "Nachhaltigkeit",
                prompt: "The output should be in German swiss style writing. ...",
                contexts: ["snbs"], // Pre-selected appropriate contexts
                description: "Prüfen Sie Ihre Projektinhalte mit dem SNBS 2.1 Hochbau.",
                questions: [],
                output: 'Dokument'
            },
            {
                name: "Datenextraktion",
                type: "Optional",
                category: "Allgemein",
                prompt: "The output should be in German swiss style writing. Extract the defined parameters in the 'JSON' from the provided parsed 'DOCUMENTS'. Use the json to structure the extracted values, write 'nicht gefunden' for values that where not found accross all documents",
                contexts: ["uploaded_documents"], // Pre-selected appropriate contexts
                description: "Extrahieren Sie vordefinierte Daten aus Dokumenten",
                questions: [],
                output: 'Dokument'
            },
        ]
    },
    ui_settings: {
        drawer_width: 240,
        pagination_page_size_options: [5,10,15,25,50],
        pagination_page_size: 25,
        search_doc_top_k: 10,
        search_doc_inner_hits: 5,
        search_doc_result_padding:50,
        profile_options: [
            { id:'sigmoidal', name: 'Sigmoidal' },
            { id:'exponential', name: 'Exponential'},
            { id:'linear', name: 'Linear' },
            { id:'hyperbolic', name: 'Hyperbolic' }
        ]
    },
    ui_messages: {
        ai_disclaimer: "Dieser Inhalt wurde automatisch unter anwendung von künstlicher Intelligenz generiert.",
        goal_category: [
            "Neu_Erstellung",
            "Erhaltung_Erneuerung",
            "Revision_Veränderung",
            "Unterhalt_Aufrechterhaltung",
            "Nutzung_Betrieb",
            "Allgemein",
        ],
        usage_category: [
            "Areal",
            "Bildung",
            "Brücke",
            "Büro",
            "Fahrnissbauten",
            "Freiland",
            "Freizeitanlage",
            "Gastronomie",
            "Gewerbe",
            "Hotelerie",
            "Industrie",
            "Kaserne",
            "Komandoposten",
            "Kraftwerk",
            "Kunstbaute",
            "Mischnutzung",
            "Schiessanlage",
            "Schutzanlage",
            "Schutzraum",
            "Spital",
            "Trasse",
            "Tunnel",
            "Wasseraufbereitung",
            "Wohnen",
            "Lärmschutz",
            "Dokument",
            "Technologie",
            "Allgemein",
        ],
    },
    search_questions: {
        general: [
            "Ziele im Projekt und Protokoll?",
            "Ist das Budget zur Verfügung und gibt es einen Bericht zur Verwendung?",
            "Welche Risiken bestehen im aktuellen Verlauf und liegt ein Risiobericht vor?",
            "Sind die Projektgrundlagen Bewertet und gibt es eine Risikobericht?"    
        ],
        gs1: [
            "Wie funktioniert eine Umwandlung einer 20-stelligen Zeichenfolge in Breiten- und Längengrad?",
            "Was ist ein Barcode?",
            "Wo plaziere ich den Barcode am besten?",
            "Wissenswertes zum Standart ISO-IEC 646?",
            "Beispiele zur Umwandlung von Maßen und Gewichten für die Anwendung?",
            "Bestimmung des Jahrhunderts in Datumsangaben?",
            "Dienstleistungsbeziehungen (in beiden Sprachen)",
            "GMN Vergaberegeln",
            "GS1 Identifikationssystem",
            "GTIN-12 und RCN-12 in einem UPC-E Strichcode",
            "Zweidimensionale Codes – QR Code",
        ]
    },
    topicMenuItems: [
        "Bauleitung",
        "Baumanagement Immobilien",
        "Bautechnik",
        "Beschaffung",
        "Boden Altlasten",
        "Brandschutz",
        "Denkmalschutz",
        "Elektro, MSRL, EMP-Schutz",
        "Energie",
        "Erdbebensicherheit",
        "Finanzen",
        "Gebäudeautomatisation",
        "Gebäudeschadstoffe",
        "Gebäudetechnik (HLKKS)",
        "Hermes",
        "Immobilien Managment",
        "Lärm",
        "Naturschutz",
        "Normen",
        "Photovoltaikanlagen",
        "Projektabschluss",
        "Projektdokumentation",
        "Rechnung",
        "Recht",
        "Safety und Security",
        "Sanitäranlagen",
        "Schutzbau",
        "Störfall, WLGF",
        "Stromversorgung",
        "Telematik",
        "Umweltmanagement und Nachhaltigkeit",
        "Wasser"
    ],
    test_data: {
        client_categories: [
            "Projektinhalte",
            "Baukosten",
            "Bauzeit",
            "Verkehrssicherheit",
            "Arbeitssicherheit",
        ],
        types: [
            "Abwicklungsaspekt",
            "Wirkungsaspekt",
        ],
        groups: [
            "übergreifende Themen",
            "Wirtschaft",
            "Gesellschaft",
            "Umwelt",
        ],
        acceptance_criteria: [
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Pflichtenheft", 
                sources: [
                    {
                        name:"Anforderung gs1, nukleus AG.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1072", 
                        type:"Document"
                    }
                ], 
                evaluation:{
                    id:"", 
                    name:"Pflichtenheft", 
                    url:"/reports/instances/48cc"
                }, 
                score: 0.3 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"AGB", 
                sources: [
                    {
                        name:"AGB_fuer_IKT-Leistungen_Ausgabe_2020_DE_gs1.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1070", 
                        type:"Document"
                    }
                ], 
                evaluation:{
                    id:"", 
                    name:"AGB", 
                    url:"/reports/instances/4aa9"
                }, 
                score: 0.2 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Geheimhaltungsvereinbarung", 
                sources: [
                    {
                        name:"Aktuelle Geheimhaltungsvereinbarung_20200503_ur_v2.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1073", 
                        type:"Document"
                    }

                ], 
                evaluation:{
                    id:"", 
                    name:"Geheimhaltungsvereinbarung", 
                    url:"/reports/instances/cdb7"
                }, 
                score: 0.7 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Vertragsentwurf", 
                sources: [
                    {
                        name:"Pflichtenheft", 
                        url:"/reports/instances/48cc", 
                        type:"Report"
                    }, 
                    {
                        name:"AGB",
                        url:"/reports/instances/4aa9", 
                        type:"Report"
                    }, 
                    {
                        name:"Geheimhaltungsvereinbarung", 
                        url:"/reports/instances/cdb7", 
                        type:"Report"
                    }, 
                    {
                        name:"AGB_fuer_IKT-Leistungen_Ausgabe_2020_DE_gs1.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1070", 
                        type:"Document"
                    }, 
                    {
                        name:"Anforderung gs1, nukleus AG.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1072", 
                        type:"Document"
                    }, 
                    {
                        name:"Aktuelle Geheimhaltungsvereinbarung_20200503_ur_v2.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1073", 
                        type:"Document"
                    }
                ], 
                evaluation:{
                    id:"", 
                    name:"Vertragsentwurf", 
                    url:"/reports/instances/bf51"
                }, 
                score: 0.5 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Vertragsvorlage und finaler Vertrag", 
                sources: [
                    {
                        name:"20240409_Anforderung nukleus AG_9-agreement final_ur.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1074", 
                        type:"Document"
                    },
                    {       
                        name:"Vertrag _nukleus AG_9-agreement final_ur.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1622", 
                        type:"Document"
                    },
                    {
                        name:"Vertragsentwurf", 
                        url: "/reports/instances/bf51", 
                        type:"Report"
                    },
                ], 
                evaluation:{
                    id:"", 
                    name:"Vertragsvorlage und finaler Vertrag", 
                    url:"/reports/instances/c00f"
                }, 
                score: 0.5 
            }
        ]         
    }
}

export default config;