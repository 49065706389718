import config from '../config';
import { cleanHtml } from '../utilities/htmlUtils';

const formatDocument = (doc) => ({
    categories: doc.categories,
    document_name: doc.document_name,
    description: doc.description,
    document_types: doc.document_types,
    fachbereiche: doc.fachbereiche
});

function getPhaseNumber(phaseName) {
    return config.assistant.phase_mapping[phaseName];
}

const reversePhaseMapping = Object.keys(config.assistant.phase_mapping).reduce((acc, key) => {
    const value = getPhaseNumber(key);
    acc[value] = key; // Swap the key and value
    return acc;
}, {});

export const convertUseToUserFriendlyName = (use) => {
    const uses = {
        wohnen: "Wohnen",
        verwaltung: "Verwaltung",
        bildungsbauten: "Bildungsbauten",
        erdgeschossnutzung: "Erdgeschossnutzung"
    }
    return uses[use];
}

export const convertPhaseToUserFriendlyPhase = (phase) => {
    const phases = {
        vorpruefung: "Vorprüfung",
        nachweisKp1: "Nachweis KP1",
        nachweisKp2: "Nachweis KP2"
    }
    return phases[phase];
}

export function context_dir (currentTenant, selectedProject) {
    const context = {
        'strategie': { path: `/${currentTenant}/${selectedProject}/Strategie`, label: 'Strategie' },
        'protokolle': { path: `/${currentTenant}/${selectedProject}/Protokolle`, label: 'Protokolle' },
        'vorgaben': { path: `/${currentTenant}/Vorgaben Vorlagen und Richtlinien`, label: 'Vorgaben' },
        'kbob': { path: `/${currentTenant}/Vorgaben Vorlagen und Richtlinien/Projektdokumentation`, label: 'KBOB' },
        'berichte': { path: ``, label: 'Berichte' },
        'snbs': { path: `/${currentTenant}/${selectedProject}`, label: 'SNBS' },
        'projektdetails': { path: ``, label: 'Projektdetails' },
        'aufgaben': { path: ``, label: 'Aufgaben' },
        'uploaded_documents': { path: ``, label: 'Hochgeladene Dokumente'}
    }
    return context;
};

export function getPhaseName(phaseNumber) {
    return reversePhaseMapping[phaseNumber];
}

/** Projektdokumentation KBOB */
export const filterAndFormatDocuments = (phase, fachbereiche, documentData) => {
    const phaseNumber = getPhaseNumber(phase);
    let documents = [];

    if (phaseNumber === 11 || phaseNumber === 12) {
        documents = documentData
            .filter(doc => doc.sia_phasen.length === 0 && 
                           fachbereiche.some(fach => doc.fachbereiche.includes(fach)))
            .map(doc => formatDocument(doc));
    } else {
        documents = documentData
            .filter(doc => {
                const matchesPhase = doc.sia_phasen.includes(phase);
                const matchesFachbereiche = fachbereiche.some(fach => doc.fachbereiche.includes(fach));
                return matchesPhase && matchesFachbereiche;
            })
            .sort((a, b) => {
                const phaseNumA = getPhaseNumber(a.phase);
                const phaseNumB = getPhaseNumber(b.phase);
                return phaseNumA - phaseNumB;
            })
            .map(doc => formatDocument(doc));
    }

    // Group documents by fachbereich
    const groupedByFachbereich = {};
    documents.forEach(doc => {
        doc.fachbereiche.forEach(fach => {
            if (fachbereiche.includes(fach)) {  // Only include relevant fachbereiche
                if (!groupedByFachbereich[fach]) {
                    groupedByFachbereich[fach] = [];
                }
                groupedByFachbereich[fach].push(doc);
            }
        });
    });

    return groupedByFachbereich;
};

/** Document Extraction */
export const generatePrompts = (jsonSchema) => {
    // Function to recursively extract keys and format them hierarchically
    const extractKeys = (obj, prefix = "") => {
      let keys = [];
      for (let key in obj) {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          // Nested object
          keys.push(`${prefix}${key}:`);
          keys = keys.concat(extractKeys(obj[key], "  " + prefix));
        } else if (Array.isArray(obj[key]) && obj[key].length > 0 && typeof obj[key][0] === "object") {
          // Array of objects
          keys.push(`${prefix}${key}:`);
          keys = keys.concat(extractKeys(obj[key][0], "  " + prefix));
        } else {
          // Leaf key
          keys.push(`${prefix}${key}`);
        }
      }
      return keys;
    };
  
    // Generate a prompt for each top-level object in the schema
    const prompts = Object.keys(jsonSchema).map((parentKey) => {
      const keys = extractKeys(jsonSchema[parentKey]);
      return `Given the following JSON data related to the parent object "${parentKey}":\n\n` +
        JSON.stringify(jsonSchema[parentKey], null, 2) +
        `\n\nPlease extract and list all keys within "${parentKey}", including nested keys, in a hierarchical structure.\n\nExample output:\n${keys.join("\n")}`;
    });
  
    return prompts;
};

/** SNBS */
export function extractAndGroupByIndicatorWithVerbund(data) {
    const groupedIndicators = {};

    data.forEach((indicator) => {
        if (!groupedIndicators[indicator.kriteriumIndikatorNr]) {
            groupedIndicators[indicator.kriteriumIndikatorNr] = {
                indicator: indicator.kriteriumIndikatorNr + " " + indicator.titel,
                verbundData: {},
            };
        }

        indicator.messgroesseVerbunde.forEach((verbund) => {
            if (!groupedIndicators[indicator.kriteriumIndikatorNr].verbundData[verbund.titel]) {
                groupedIndicators[indicator.kriteriumIndikatorNr].verbundData[verbund.titel] = [];
            }

            const messgroessenArray = groupedIndicators[indicator.kriteriumIndikatorNr].verbundData[verbund.titel];
            let firstEntry = null;

            verbund.messgroessen.forEach((messgroesse) => {
                // If the allgemeinerText is the default string, copy values from the first entry
                if (messgroesse.allgemeinerText === "Gleich wie bei Messgrösse 1" && firstEntry) {
                    messgroessenArray.push({
                        allgemeinerText: firstEntry.allgemeinerText,
                        vorpruefung: firstEntry.vorpruefung,
                        nachweisKp1: firstEntry.nachweisKp1,
                        nachweisKp2: firstEntry.nachweisKp2,
                        messgroesseTitel: messgroesse.titel,
                    });
                } else {
                    // Save the first entry for future copying
                    const newEntry = {
                        allgemeinerText: messgroesse.allgemeinerText,
                        vorpruefung: messgroesse.vorpruefung,
                        nachweisKp1: messgroesse.nachweisKp1,
                        nachweisKp2: messgroesse.nachweisKp2,
                        messgroesseTitel: messgroesse.titel,
                    };
                    messgroessenArray.push(newEntry);

                    if (!firstEntry) {
                        firstEntry = newEntry;
                    }
                }
            });
        });
    });

    // Format the final result
    return Object.values(groupedIndicators).map((group) => ({
        indicator: group.indicator,
        verbundData: Object.entries(group.verbundData).map(([verbundTitel, messgroessen]) => ({
            verbundTitel,
            messgroessen,
        })),
    }));
}

export const uniqueMessgroessenByPhase = (data, phase, usage) => {
    const uniqueMap = new Map();

    data.forEach((indicator) => {
        indicator.verbundData.forEach((verbund) => {
            // Skip if the verbundTitel does not contain the usage string
            if (!verbund.verbundTitel.toLowerCase().includes(usage.toLowerCase())) return;

            verbund.messgroessen.forEach((messgroesse) => {
                const phaseContent = messgroesse[phase];
                
                if(phaseContent === '' || !phaseContent) return;

                const clean_content = cleanHtml(phaseContent);
                const key = JSON.stringify({ 
                    phaseContent: clean_content
                });

                if (!uniqueMap.has(key)) {
                    uniqueMap.set(key, {
                        indicator: indicator.indicator,
                        [phase]: clean_content,
                        messgroesseTitles: messgroesse.messgroesseTitel,
                        titles: verbund.verbundTitel
                    });
                } else {
                    uniqueMap.get(key).messgroesseTitles += `, ${messgroesse.messgroesseTitel}`;
                }
            });
        });
    });

    return Array.from(uniqueMap.values());
};

export function createEvaluationMessage(dataArray) {
    const baseMessage = `
Sie haben die Aufgabe, die Relevanz der abgerufenen Dokumente zu bewerten, um die vorgegebenen Qualitätsindikatoren zu beurteilen. Analysieren Sie für jeden angegebenen Indikator das Feld „Ergebnisse“, das die Dokumente und ihren Inhalt („Pfad“ und „Textabschnitte“) enthält. 

Vergeben Sie für jeden Indikator eine einzige Punktzahl von 0 bis 2 auf der Grundlage der Relevanz aller bereitgestellten Dokumente zusammengenommen:
- 0: 🔴 Unzureichende Daten für eine ordnungsgemäße Validierung gefunden, keine thematische Korrelation.
- 1: 🟡 Teilweise hilfreich, passende Begriffe gefunden, aber keine detaillierten Informationen.
- 2: 🟢 Ausreichende Daten (nicht nur Schlüsselwörter) für einen gründlichen Nachweis gefunden.

Geben Sie Ihre Bewertung in einem strukturierten Format für jeden Indikator ab.

---

`;
    // Build dynamic message for each indicator
    const indicatorsDetails = dataArray.map((data, i) => {
        const indicatorHeader = `### Indicator ${i + 1}: ${data.indicator_name}`;
        const phase = `### Phase: ${data.phase}`;
        const criteria = `### Evaluation Criteria (Query): ${data.query}`;
        
        // Check if 'result' exists and is an array
        if (!data.result || !Array.isArray(data.result) || data.result.length === 0) {
            return `${indicatorHeader}\n${phase}\n${criteria}\n\n**No documents found for this indicator.**`;
        }

        // Combine details of all documents
        const resultsDetails = data.result.map((doc, index) => {
            const docHeader = `**Document ${index + 1} - Path:** ${doc.file_metadata.path}`;
            const textSections = doc.text_sections.map((section, j) => {
                return `- **Text Section ${j + 1} (Page ${section.page_number}):** "${section.text}"`;
            }).join("\n");
            return `${docHeader}\n${textSections}`;
        }).join("\n\n");

        return `${indicatorHeader}\n${phase}\n${criteria}\n\n### Retrieved Documents and Contents:\n${resultsDetails}`;
    }).join("\n\n---\n\n");

    // Combine all parts into a final message
    const finalMessage = `${baseMessage}${indicatorsDetails}

### Anweisungen:
Analysieren Sie für jede Kriteriensammlung die Dokumente, um festzustellen, ob sie ausreichende Informationen für eine fachlich fundierte Bewertung der angegebenen Kriterien in strenger Weise (mit ausführlichen Detailinformationen und technischen Beschreibungen) enthalten. Ein paar Sätze oder eine einfache Wortübereinstimmung sind unzureichend (NOT ENOUGH), um eine seriöse Bewertung eines Themas vorzunehmen und sollten 0 oder 1 ergeben. Vergeben Sie eine einzige Punktzahl (0, 1 oder 2) für die Kriteriensammlung und die bereitgestellten Dokumente und geben Sie eine kurze Erklärung für Ihre Punktzahl.

Ihre Ausgabe sollte diesem Format folgen:
- Bewertung: [🔴/🟡/🟢] ([0-2]) 
- Erläuterung: [Erläutern Sie, warum]
`;

    return finalMessage;
}