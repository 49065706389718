export const cleanHtml = (htmlContent) => {
    if (!htmlContent) return "";

    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Extract text content from list items and join them
    const listItems = Array.from(tempDiv.querySelectorAll("li"))
        .map((li) => li.textContent.trim())
        .join(", ");

    // Extract remaining text from the document excluding list items
    const otherText = tempDiv.textContent
        .replace(/(\n|\r|\t)+/g, " ") // Remove line breaks
        .trim();

    // Combine list items with other text, prioritizing structured content
    return listItems ? listItems : otherText;
};