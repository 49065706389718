import * as React from 'react';
import Box from '@mui/material/Box';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Typography, Grid, Card, CardHeader, CardContent, Collapse, Avatar, 
    IconButton, Button, Link, Chip, List, ListItem, ListItemText
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import parse from 'html-react-parser';
import { getUrlForDocument } from '../utilities/pathUtils';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

export default function SearchResultDocs(props) {
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const formatParseDate = (date) => {
        return `Datei verarbeitet am: ${date}`;
    }

    const formatFormat = (format) => {
        if (format) {
            return `Datei format: ${format.split('.')[1].toUpperCase()}`;
        }
    }

    const formatScore = (score) => {
        return "Relevanz: " + parseFloat(score).toFixed(2);
    };

    function EmphasizedText({ htmlContent }) {
        const options = {
            replace: domNode => {
                if (domNode.name === 'em') {
                    return <em style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{domNode.children[0].data}</em>;
                }
            }
        };
    
        return (
            <Typography variant="body1" component="div">
                {parse(htmlContent, options)}
            </Typography>
        );
    }

    const { matchObject } = props;
    const highlightCount = matchObject.text_sections.length;

    return (
        <Box sx={{ width: '100%', textAlign:'left', padding: '2em 0.5em 2em 0.5em'}}>       
            <Card>
                <CardHeader
                    sx={{paddingBottom:1}}
                    avatar={
                    <Avatar sx={{ bgcolor: 'black' }} aria-label="recipe">
                        D
                    </Avatar>
                    }
                    action={
                        <Box>
                            <Link sx={{padding:2}}
                            href={ getUrlForDocument(props?.matchObject?.file_metadata?.path || null, props?.matchObject?.file_metadata?.id || null) } target="_blank">
                                <Typography variant='button' sx={{padding:2, marginBottom:'20px'}}>
                                    Dokument öffnen 
                                </Typography>
                                <OpenInNewIcon />
                            </Link>
                            
                            <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>

                            <IconButton disabled aria-label="pin">
                                <PushPinIcon />
                            </IconButton>
                            
                        </Box>
                    }
                    title={
                        <Box>
                            <Button 
                            onClick={handleExpandClick}
                            variant='text'>
                                <Typography 
                                variant="h6">
                                    <strong>{props.matchObject.file_metadata.name}</strong>
                                </Typography>
                            </Button>
                            <Typography 
                            variant="body2"
                            sx={{padding:1}}>
                                {formatParseDate(props.matchObject.creation_date)}
                            </Typography>
                            {/* Chip displaying the client value */}
                            {props.matchObject.client && (
                                <Chip sx={{margin:0.5}} label={props.matchObject.client} />
                            )}
                            {/* Chip displaying the project value */}
                            {props.matchObject.project && (
                                <Chip sx={{margin:0.5}} label={props.matchObject.project} />
                            )}
                            
                        </Box>
                    }
                    subheader={
                        <Box sx={{padding:1}}>
                            {formatFormat(props.matchObject.format)}
                        </Box>
                        
                    }
                >
                </CardHeader>       

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent  sx={{ 
                        margin: 1, // default margin
                        [theme.breakpoints.up('sm')]: { margin: 2 },
                        [theme.breakpoints.up('md')]: { margin: 3 },
                        [theme.breakpoints.up('lg')]: { margin: 4 }
                    }}
                    >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                                Dokument gefunden im Pfad:
                            </Typography>
                            <Typography sx={{whiteSpace: 'pre-wrap'}}>
                                {props.matchObject.file_metadata.path}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                            Übereinstimmungen in Dokument gefunden: {highlightCount}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                            {matchObject.text_sections.map((match, index) => {
                                return (
                                <ListItem key={index}>
                                    <ListItemText
                                    primary={
                                        <div>
                                        <strong>{index + 1}. </strong>
                                        <strong>Auf Seite {match.page_number} gefunden:</strong>
                                        <EmphasizedText htmlContent={match.highlight} />
                                        {formatScore(match.score)}
                                        </div>
                                    }
                                    />
                                </ListItem>
                                );
                            })}
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
                </Collapse>
            </Card>
        </Box>  
    );
}
