import React, { useState, useEffect } from 'react';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { 
  Card, CardContent, CardHeader, Link, List, CircularProgress, Autocomplete,
  Chip, Typography, IconButton, TextField, Button,  
  Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useAppContext } from '../contexts/AppContext';
import config from '../config';
import { getUrlForProjectPath } from '../utilities/pathUtils';

const TaskList = ({ dataGridRows }) => {
    const [rows, setRows] = useState([]);
    const apiRef = useGridApiRef();
    const { queryGenerativeModel } = useAppContext();
    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [recentInputs, setRecentInputs] = useState([]);

    useEffect(() => {
      // Simulating a fetch-like operation
      setRows(dataGridRows);
    }, [dataGridRows]);

    // Function to handle input change and track last 3 inputs
    const handleInputChange = (event, newInputValue) => {
      // Update only the input value, but do not modify recent inputs here
      setInputValue(newInputValue);
    };

    const handleSelectionChange = () => {
      const selectedRowsMap = apiRef.current.getSelectedRows(); // Get a map of selected rows
      const selectedRowData = Array.from(selectedRowsMap.values()); // Convert the map to an array of row objects
      setSelectedRows(selectedRowData);  // Update the selected rows state
    };

    const handleQuestionData = async () => {
      setIsLoading(true);
    
      const selectedData = selectedRows.map(row => {
        const fileNames = row.documents.map(doc => doc.file_name).join(', ');
        return `Aufgabe: ${row.text}, Verantwortlicher: ${row.assignee}, Fälligkeitsdatum: ${row.due_date}, Im Dokumenten gefunden: ${fileNames}`;
      }).join('\n\n'); // Separate each row by double new lines for readability

      const messages = [
          { role: 'system', content: "You are a Question-answering assistant, specialized in the construction and real-estate industry. You will be provided with user questions (USER_QUERY) regarding project TASKS (Kontext). Use this information to answer the user questions. Include the names of the SOURCES used to provide the answer. Never return the complete content of a document. Include the user query in the response at the beggining, labeled 'Frage:'. Provide an answer in German." },
          /* { role: 'assistant', content: 'REFERENCES: ' + referencesString }, */
          { role: 'assistant', content: 'Kontext: ' + selectedData }, 
          { role: 'user', content: 'USER_QUERY: ' + inputValue }
      ];
      const queryResponse = await queryGenerativeModel("openai", "gpt-4o-mini", messages);
      if (!queryResponse) queryResponse = 'Ergebnisse konnten nicht generiert werden. Bitte versuchen Sie es nochmals.'
      setResponse(queryResponse);

      // Update recent inputs after the question is sent
      if (inputValue && !recentInputs.includes(inputValue)) {
        setRecentInputs((prev) => {
          const updatedInputs = [inputValue, ...prev];
          return updatedInputs.slice(0, 3);  // Keep only the last 3 inputs
        });
      }
      setIsLoading(false);
      setInputValue(''); // Clear input field after adding
    }
  
    const [expanded, setExpanded] = useState(true); // Default state is collapsed
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const isDelayedStatus = ( status ) => {
      if (status === true) {
        return (
          "vergangene"
        )
      } else if (status === null) {
        return (
          "unbekannt"
        )
      } else {
        return (
          "bevorstehende"
        )
      }
    };

    const dataGridColumns = [
      { field: 'text', headerName: 'Aufgabe', flex:2, },
      { field: 'assignee', headerName: 'Verantwortlicher', flex:0.5,},
      { field: 'due_date', headerName: 'Fälligkeitsdatum', flex:0.5, },
      { field: 'documents', headerName: 'Im Dokument gefunden', flex:1.5, 
        renderCell: (params) => {
         return(   
            <List variant="body2">
              {params.row.documents.map((doc, index) => (   
                  <Link key={index} sx={{padding:1}} target="_blank" href={getUrlForProjectPath(doc.path, doc.document_id)} rel="noreferrer">{doc.path}</Link>                                                 
              ))}
            </List>            
          )
        }
      },
      { field: 'status', headerName: 'Status', flex:0.5,
        renderCell: (params) => {
         return(   
          
          <Chip label={isDelayedStatus(params.row.status)} color={params.row.status === true ? 'default' : (params.row.status === null ? 'warning' : 'success')} /> 
                             
          )
        },
        valueGetter: (params) => isDelayedStatus(params.row.status),
      }
    ];

    // Using row index as a unique identifier
    const getRowId = React.useCallback((row, index) => `${row.text}-${index}`, []);
  
    return (
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardHeader 
                action={
                <IconButton onClick={handleExpandClick} sx={{ marginLeft: 'auto', padding: 0 }}>  
                </IconButton>
            }
            title={
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleExpandClick}>
                        <AutoAwesomeIcon color="secondary" sx={{ verticalAlign: 'middle', marginRight: 1, marginBottom:2 }} />
                        <Tooltip title={config.ui_messages.ai_disclaimer}>
                            <IconButton sx={{ padding: 0, marginRight: 2 }}> 
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        Wesentliche Aufgaben
                    </Typography>
            } 
            sx={{ textAlign: 'left', paddingBottom: 0 }}
        />
          <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
            <DataGridPro 
                rowHeight={46} // Default is 52
                getRowId={getRowId}
                initialState={
                  {
                    pagination: { paginationModel: { pageSize: 7 } },
                  }
                }
                apiRef={apiRef}
                width='100%' 
                checkboxSelection
                rows={rows} 
                columns={dataGridColumns} 
                pagination
                pageSizeOptions={config.ui_settings.pagination_page_size_options}
                onRowSelectionModelChange={handleSelectionChange}
            />
            <Autocomplete
              freeSolo
              options={recentInputs}  // Display the last 3 recent inputs
              value={inputValue}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Frage stellen"
                  variant="outlined"
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />
              )}
            />
            {isLoading ? (
              <CircularProgress sx={{ float: 'right', margin: '8px' }} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleQuestionData}
                disabled={selectedRows.length === 0 || inputValue.trim() === ''}
                sx={{ float: 'right' }}
              >
                Frage stellen
              </Button>
            )}
            <TextField
                fullWidth
                label="Antwort"
                multiline
                variant="outlined"
                value={response}
                InputProps={{
                    readOnly: true,
                }}
                sx={{
                  marginTop: 2,
                }}
                minRows={1}  // Start with one row, it will grow dynamically
                maxRows={Infinity}  // Let it grow without limit
            />

            </CardContent>  
        </Card>
      
    )  
  }

  export default TaskList;