import React from 'react';
import Button from '@mui/material/Button';
import { getUrlForPath } from '../utilities/pathUtils';

// Example of how your component might look
function LinkToFiles(props) {

    const handleButtonClick = (path) => {
        const url = getUrlForPath(path);
        window.open(url, '_blank');
    };

    return (
        <Button 
        size="small" 
        sx={{ 
            padding: '6px 8px', // Adjust padding to make the button slimmer
            textTransform: 'none', // Prevents text from transforming to uppercase
            
        }} 
        onClick={() => handleButtonClick(props.path)} 
        variant="outlined">
            <span className="break-words overflow-hidden">{props.path}</span>
        </Button>

    );
};

export default LinkToFiles;
